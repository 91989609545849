import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import ContactPageTemplate from '../components/ContactPageTemplate'
import config from '../../config'
import SE0 from '../components/SEO'
import Layout from '../components/Layout'

const ContactPage = (props) => {
  const { data: { markdownRemark: { frontmatter: { title, subtitle, meta_title, meta_description, keywords } } } } = props
  for (let i=1;i<keywords.length;i++){
    if (keywords[i].charAt(0)!==' '){
    keywords[i] = ' ' + keywords[i];
    }
    //console.log(keywords[i]);
  }
  //console.log(String(keywords));

  return (
    <Layout>
    <SE0
      title={title}
      meta_title={meta_title}
      meta_desc={meta_description}
      keywords={keywords}
      slug="/contact/"
      cover="/img/kontakt.svg"
      siteTitleAlt={config.siteTitleAlt}
      userName={config.userName}
      siteTitle={config.siteTitle}
      siteUrl={config.siteUrl}
      siteFBAppID={config.siteFBAppID}
      userTwitter={config.userTwitter}
      pathPrefix={config.pathPrefix}
    />
    <Helmet htmlAttributes={{ lang : 'en-en', dir:'ltr' }}>
      <script type='application/ld+json'>
      {JSON.stringify({
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": "https://artopen.co/"
        },{
          "@type": "ListItem",
          "position": 2,
          "name": "Contact",
        }]
      })}
      </script>
    </Helmet>
      <ContactPageTemplate
        title={title}
        subtitle={subtitle}
      />

    </Layout>
  )
}

ContactPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default ContactPage

export const contactPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        subtitle
        meta_title
        meta_description
        heading
        keywords
      }
    }
  }
`
