import loadable from '@loadable/component'
import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const ModalCall = loadable(() => import('../ModalCall'))
const MapContainer = loadable(() => import('../Map'))
const ContactForm = loadable(() => import('../forms/ContactForm'))


const ContactPageTemplate = (props) => {
  const { title, subtitle, meta_title, meta_description } = props

  return (
    <>
      <section className='hero is-primary is-bold' style={{minHeight:'70%',backgroundImage:'url(/img/kontakt.svg)',backgroundSize:'cover',backgroundRepeat:'no-repeat',backgroundPosition:'bottom',backgroundAttachment: `fixed`}}>
        <div className='hero-body'>
        <div className='container section'>
          <div className='columns section'>
            <div>
              <h1 className='title' style={{fontSize:'50px'}}>
                {title}
              </h1>
              <h2 className='subtitle'>
                {subtitle}
              </h2>
            </div>
          </div>
        </div>
        </div>
      </section>
      <nav className="breadcrumbs-nav section">
      <Link className="inline-link" to="/">Home</Link>&nbsp;&gt;&nbsp;<span style={{color:'#23C495'}}><b>{title}</b></span>
      </nav>

      <div className='columns' style={{marginLeft:'10%',marginRight:'10%',marginTop:'-20px',marginBottom:'50px'}}>
        <div className='column' style={{lineHeight:'40px'}}>
          <h3 className='subtitle'><b>Customer service office</b></h3>
          <h4 style={{marginBottom:'30px'}}>
          <img width="20px" style={{verticalAlign:'middle', marginRight:'15px'}} alt="opening hours" src='/img/clock.svg'/>
          pon. - pt: 8:00 - 16:00</h4>

          <p>
          <img width="20px" style={{verticalAlign:'middle', marginRight:'15px'}}  alt="street" src='/img/map-marker-alt.svg'/>ul. Piotra Ignuta 87</p>

          <p>
          <img width="20px" style={{verticalAlign:'middle', marginRight:'15px'}}   alt="city" src='/img/city.svg'/>54-151 Wrocław</p>


          <br />

          <h4 style={{marginBottom:'20px'}}> Contact by email or phone: </h4>
          <div style={{marginBottom:'20px'}}>
          <a href="mailto:bok@artopen.pl" className='button-green' >
          <img width="20px"  style={{verticalAlign:'middle',marginLeft:'5px', marginRight:'15px'}}   alt="email" src='/img/envelope-alt.svg'/>bok@artopen.pl</a>
          </div>

          <div style={{marginBottom:'20px'}}>
          <a href="tel:+48 71 728 29 13" className='button-green' >
          <img width="20px"  style={{verticalAlign:'middle',marginLeft:'5px', marginRight:'15px'}}   alt="phone number" src='/img/phone.svg'/>Tel: +48 71 728 29 13</a>
          </div>

          <div style={{marginBottom:'20px'}}>
            <a href="tel:+48 535 000 881" className='button-green' >
            <img width="20px"  style={{verticalAlign:'middle',marginLeft:'5px', marginRight:'15px'}}   alt="cellphone number" src='/img/mobile.svg'/>Kom: +48 535 000 881</a>
          </div>
          <br />
          <div style={{textAlign:'center'}}>
          <img src="/img/kontakt-center.svg" width="70%" style={{marginLeft:'-5%'}} alt="contact" />
          </div>
        </div>
        <div className='column' style={{minWidth:'55%'}}>
          <h4 className='subtitle'><b>Contact form</b></h4>
          <h4 style={{marginBottom:'30px'}}>Send us a message!</h4>
          <br />
          <ContactForm />
        </div>

      </div>
      <br /><br />
      <ModalCall />
      <br />
      <h5 style={{textAlign:'center'}} className='subtitle'>
      <img width="40px"  style={{verticalAlign:'middle', marginBottom:'15px'}}   alt="find us" src='/img/map-marked.svg'/>
      <br />
      <a href="/contact/sukces/" style={{display:'none'}}></a>
      <b>Visit our headquarters:</b> </h5>
      <h6 style={{textAlign:'center'}}> Our coffee maker is waiting for you! </h6>
      <br />
      <MapContainer />
</>
  )
}


ContactPageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
}

export default ContactPageTemplate
